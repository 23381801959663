import React from 'react';
import './EtudesExpertises.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faClipboardCheck, faShieldAlt, faLightbulb } from '@fortawesome/free-solid-svg-icons';

//import contactSupportImage from '../../images/contact-support.png';
//import gestionBienImage from '../../images/gestionbien.jpg';

function EtudesExpertises() {
  return (
    <div className="service-page">
      <div className="etudes-header">
        <h1>Études et Expertises Immobilières</h1>
        <p>Évaluez la valeur de votre bien et assurez sa conformité avec l'aide de nos experts.</p>
      </div>

      <div className="etudes-content">
        <div className="etudes-text">
          <h2>Pourquoi opter pour nos services d'études et d'expertises ?</h2>
          <p>
            Chez <strong>Mentor Business Services</strong>, nous comprenons l'importance de connaître la valeur exacte de votre bien immobilier et de garantir qu'il respecte toutes les normes en vigueur. Nos services d'études et d'expertises vous offrent une analyse détaillée pour vous permettre de prendre les meilleures décisions.
          </p>

          <h3>Nos Services d'Études et d'Expertises</h3>
          <div className="service-list">
            <div className="service-item-eudes">
              <FontAwesomeIcon icon={faHome} className="service-icon-etudes" />
              <div>
                <h4>Évaluation Immobilière</h4>
                <p>Nous réalisons une évaluation précise de la valeur marchande de votre bien, basée sur des critères rigoureux.</p>
              </div>
            </div>
            <div className="service-item-eudes">
              <FontAwesomeIcon icon={faClipboardCheck} className="service-icon-etudes" />
              <div>
                <h4>Audit Technique</h4>
                <p>Nous examinons l'état technique de votre bien pour identifier les éventuelles réparations ou améliorations à envisager.</p>
              </div>
            </div>
            <div className="service-item-eudes">
              <FontAwesomeIcon icon={faShieldAlt} className="service-icon-etudes" />
              <div>
                <h4>Conformité aux Normes</h4>
                <p>Nous vérifions que votre bien est en conformité avec les réglementations locales et internationales.</p>
              </div>
            </div>
            <div className="service-item-eudes">
              <FontAwesomeIcon icon={faLightbulb} className="service-icon-etudes" />
              <div>
                <h4>Conseils Personnalisés</h4>
                <p>Nos experts vous fournissent des recommandations sur les meilleures stratégies à adopter pour maximiser la valeur de votre bien.</p>
              </div>
            </div>
          </div>

          <h3>Nos Zones d'Intervention</h3>
          <p>
            Nos services d'études et d'expertises couvrent les régions de Dakar et de Thiès, ainsi que d'autres localités au Sénégal. Grâce à notre connaissance approfondie du marché immobilier local, nous vous offrons des services adaptés à vos besoins spécifiques.
          </p>
        </div>

        <div className="etudes-images">
          <div className="image-card">
            <img src="/images/contact-support.png" alt="Contactez-nous" />
            <a href="/contact" className="image-link-eudes">Contactez-nous</a>
          </div>
          <div className="image-card">
            <img src="/images/gestionbien.jpg" alt="Gérez votre bien" />
            <a href="/gerance/formulaire" className="image-link-eudes">Confiez-nous votre bien</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EtudesExpertises;
