import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import Modal from 'react-modal';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './DetailPage.css';
import axios from 'axios';

// Import des icônes FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faStar, faUser, faFileAlt, faBed, faCouch, faBath, faUserFriends, faCity, faBuilding, faGlobe, faTag, faDollarSign, faTools, faCalendarCheck, faInfoCircle, faClipboardList, faCogs } from '@fortawesome/free-solid-svg-icons';


Modal.setAppElement('#root'); // Pour l'accessibilité

function DetailPage() {
  const { id } = useParams();
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true); // État pour gérer le chargement
  const [error, setError] = useState(null); // État pour gérer les erreurs
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  // Fonction pour récupérer les détails de la propriété depuis l'API
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        //const response = await axios.get(`http://localhost:5000/api/properties/${id}`); // Appel API
        const response = await axios.get(`https://booking-backend-3897450fe5a2.herokuapp.com/api/properties/${id}`); // Appel API
        setLocation(response.data); // Stocker la donnée de la propriété dans l'état
        setLoading(false); // Arrêter le chargement
      } catch (error) {
        console.error('Erreur lors de la récupération de la propriété:', error);
        setError('Erreur lors de la récupération de la propriété');
        setLoading(false); // Arrêter le chargement en cas d'erreur
      }
    };

    fetchLocation(); // Appeler la fonction de récupération des données
  }, [id]);

  // Si les données sont en cours de chargement
  if (loading) {
    return <div>Chargement...</div>;
  }

  // Si une erreur s'est produite
  if (error) {
    return <div>{error}</div>;
  }

    if (!location) {
      return <div>Location non trouvée</div>;
    }

  // Récupérer les chemins d'images. Assurez-vous que ces chemins pointent vers le dossier public.
  const images = location.images.map(image => {
    // Remplacez le chemin relatif par un chemin absolu à partir du dossier public
    return image.startsWith('./images/') ? `/images/${image.split('/').pop()}` : image;
  });  

  console.log('images:', images);
  

  const openModal = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="detail-page-container">
      <div className="detail-main-content">
        <h1>{location.title}</h1>
        <p className="location-icon">
          <i className="fas fa-map-marker-alt"></i> {location.localisation}
        </p>

        <div className="detail-carousel">
          <Carousel className="detail-page-carousel" showThumbs={true} infiniteLoop={true}>
            {images.map((image, index) => (
              <div key={index} onClick={() => openModal(index)}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ))}
          </Carousel>
        </div>

        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Image Gallery"
          className="image-modal"
          overlayClassName="image-modal-overlay"
        >
          <button onClick={closeModal} className="modal-close-button">Fermer</button>
          <h2>{location.title}</h2>
          <Carousel className="detail-page-carousel"
            selectedItem={photoIndex}
            showThumbs={true}
            infiniteLoop={true}
            showIndicators={false}
            showStatus={true}
          >
            {images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Slide ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </Modal>

        <div className="detail-section detail-description">
          <h2><FontAwesomeIcon icon={faInfoCircle} /> Description</h2>
          <div className="detail-row">
            <p>{location.description}</p>
          </div>
        </div>

        <div className="detail-section detail-address">
          <h2><FontAwesomeIcon icon={faMapMarkerAlt} /> Adresse</h2>
          <div className="detail-row">
            <p><FontAwesomeIcon icon={faCity} /> Ville : {location.details.city}</p>
            <p><FontAwesomeIcon icon={faBuilding} /> Quartier : {location.details.district}</p>
          </div>
          <div className="detail-row">
            <p><FontAwesomeIcon icon={faGlobe} /> Pays : {location.details.country}</p>
            <p><FontAwesomeIcon icon={faBuilding} /> Nom : {location.details.name}</p>
          </div>
        </div>

        <div className="detail-section detail-info">
          <h2><FontAwesomeIcon icon={faClipboardList} /> Détails</h2>
          <div className="detail-row">
            <p><FontAwesomeIcon icon={faTag} /> Référence : {location.details.reference}</p>
            <p><FontAwesomeIcon icon={faDollarSign} /> Prix : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(location.details.price)} par {location.details.sequence}</p>
          </div>
          <div className="detail-row">
            <p><FontAwesomeIcon icon={faBed} /> Chambres : {location.rooms}</p>
            <p><FontAwesomeIcon icon={faCouch} /> Salons : {location.lounge}</p>
          </div>
          <div className="detail-row">
            <p><FontAwesomeIcon icon={faBath} /> Salles de bains : {location.bathrooms}</p>
            <p><FontAwesomeIcon icon={faUserFriends} /> Lits : {location.bed}</p>
          </div>
        </div>

        <div className="detail-section detail-amenities">
          <h2><FontAwesomeIcon icon={faCogs} /> Installations & Commodités</h2>
          <ul>
            {location.amenities.map((amenity, index) => (
              <li key={index}><FontAwesomeIcon icon={faTools} /> {amenity}</li>
            ))}
          </ul>
        </div>

        <div className="detail-section detail-description">
          <h2><FontAwesomeIcon icon={faFileAlt} /> Réglement intérieur</h2>
          <div className="detail-row">
            <p>{location.regulation}</p>
          </div>
        </div>

      </div>

      <div className="detail-contact-container">

        <div className="detail-contact-form">
          <h2><FontAwesomeIcon icon={faCalendarCheck} /> Programmer une visite?</h2>
          <form>
            <input type="text" placeholder="Nom" />
            <input type="email" placeholder="Email" />
            <input type="tel" placeholder="Téléphone" />
            <textarea placeholder={`Je suis intéressé par votre annonce [ ${location.title} ]`} />
            <button type="submit">Envoyer Un Email</button>
          </form>
        </div>

        <div className="detail-reservation">
          <h2>{location.details.price} CFA par nuit</h2>
          <div className="stars">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <div className="reservation-dates">
            <label>Date :</label>
            <div className="date-picker">
              <p>Arrivée</p>
              <input type="date" placeholder="Arrivée" />
              <span>➔</span>
              <p>Départ</p>
              <input type="date" placeholder="Départ" />
            </div>
          </div>
          <div className="reservation-guests">
            <label>Voyageur(s) :</label>
            <div className="guest-picker">
              <button><FontAwesomeIcon icon={faUser} /> 1 Adulte, 0 Enfant</button>
            </div>
          </div>
          <div className="reservation-price">
            <div className="price-item">
              <span>880 600 CFA /Mois * Nbre de nuits :</span>
              <strong>880 600 CFA</strong>
            </div>
            <div className="price-item">
              <span>FRAIS SERVICE :</span>
              <strong>3%</strong>
            </div>
            <div className="price-item total">
              <span>TOTAL :</span>
              <strong>0 CFA</strong>
            </div>
          </div>
          <button className="btn-reservation">Réserver</button>
          <p className="reservation-note">Vous ne serez débité que si vous confirmez</p>
        </div>

      </div>

    </div>
  );
}

export default DetailPage;
