import React from 'react';
import './Location.css';

function Location() {
  return (
    <div className="service-page">
      <div className="location-header">
        <h1>Service de Location Immobilière</h1>
        <p>Faites confiance à Mentor Business Services pour louer vos biens rapidement et efficacement.</p>
      </div>
      
      <div className="location-content">
        <h2>Pourquoi nous choisir pour la location de vos biens ?</h2>
        <p>
          Chez Mentor Business Services, nous savons que trouver le locataire idéal peut être un défi. C’est pourquoi nous vous offrons un service de location complet et personnalisé. Que vous soyez propriétaire d'un appartement, d'une villa ou d'un local commercial, nous avons l'expertise pour vous accompagner tout au long du processus.
        </p>

        <h3>Nos Services de Location</h3>
        <ul>
          <li><strong>Sélection des Locataires :</strong> Nous effectuons une sélection rigoureuse des locataires potentiels pour assurer que votre bien soit entre de bonnes mains.</li>
          <li><strong>Promotion de Votre Bien :</strong> Nous utilisons les meilleures stratégies de marketing pour assurer une visibilité maximale à votre bien.</li>
          <li><strong>Négociation et Signature des Baux :</strong> Nous gérons toutes les négociations contractuelles et nous nous assurons que les baux sont signés conformément aux lois en vigueur.</li>
          <li><strong>Suivi des Loyers :</strong> Nous veillons à ce que les loyers soient perçus à temps et nous gérons les éventuels retards de paiement.</li>
        </ul>

        <h3>Nos Zones d'Intervention</h3>
        <p>
          Nous couvrons principalement les régions de Dakar et Thiès, mais nos services sont disponibles partout au Sénégal. Nous connaissons bien le marché immobilier local et nous utilisons cette connaissance pour vous offrir le meilleur service possible.
        </p>
      </div>
    </div>
  );
}

export default Location;
