import React, { useState } from 'react';
import './Signup.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Signup() {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  //const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, surname, email, password, confirmPassword } = formData;

    // Vérifier que tous les champs sont remplis
    if (!name || !surname || !email || !password || !confirmPassword) {
      setError('Tous les champs sont obligatoires.');
      return;
    }

    // Vérifier si les mots de passe correspondent
    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      return;
    }

    try {
      // Effectuer la requête POST pour l'inscription
      const response = await axios.post('https://booking-backend-3897450fe5a2.herokuapp.com/api/users/signup', { name, surname, email, password });
      //setSuccessMessage(response.data.message || 'Inscription réussie ! Veuillez vérifier votre e-mail pour confirmer votre compte. !');
      console.log('Inscription réussie ! Veuillez vérifier votre e-mail pour confirmer votre compte.');
      
      // Réinitialiser les messages d'erreur et les champs du formulaire
      setError('');
      setFormData({
        name: '',
        surname: '',
        email: '',
        password: '',
        confirmPassword: '',
      });

      // Rediriger vers la nouvelle page EmailSent
      navigate('/email-sent', { state: { message: response.data.message || 'Inscription réussie ! Veuillez vérifier votre e-mail pour confirmer votre compte.' } });

    } catch (err) {
      // Afficher le message d'erreur provenant du serveur
      setError(err.response?.data?.message || 'Erreur lors de l\'inscription. Veuillez réessayer.');
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-box">
        <h2>Créer un compte</h2>
        <form onSubmit={handleSubmit} className="signup-form">
          <div className="input-group">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label>Nom</label>
            <i className="fas fa-user"></i>
          </div>

          <div className="input-group">
            <input
              type="text"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              required
            />
            <label>Prénom</label>
            <i className="fas fa-user"></i>
          </div>

          <div className="input-group">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label>Email</label>
            <i className="fas fa-envelope"></i>
          </div>

          <div className="input-group">
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <label>Mot de passe</label>
            <i className="fas fa-lock"></i>
          </div>

          <div className="input-group">
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
            <label>Confirmer le mot de passe</label>
            <i className="fas fa-lock"></i>
          </div>

          {error && <p className="error-message">{error}</p>}

          <button type="submit" className="btn-signup">S'inscrire</button>
        </form>
        <p className="signup-footer">
          Vous avez déjà un compte ? <a href="/login">Se connecter</a>
        </p>
      </div>
    </div>
  );
}

export default Signup;
