import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

function Contact() {
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      process.env.REACT_APP_EMAILJS_USER_ID
    );    

    const templateParams = {
      to_name: 'Mentor Business Service',
      from_name: `${formState.firstName} ${formState.lastName} ${formState.email} ${formState.phone}`,
      message: formState.message,
      reply_to: formState.email
    };

    /*emailjs.send('service_2fsv8vd', 'template_68h2ndj', templateParams, 'D2z5OCSifE1nkc4HW')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setStatusMessage('Votre message a été envoyé avec succès.');
        setFormState({ firstName: '', lastName: '', email: '', phone: '', message: '' }); // Reset form
      }, (err) => {
        console.log('FAILED...', err);
        setStatusMessage('Une erreur s\'est produite, veuillez réessayer.');
      });
  };*/
  emailjs.send(
    process.env.REACT_APP_EMAILJS_SERVICE_ID,
    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    templateParams,
    process.env.REACT_APP_EMAILJS_USER_ID
  )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setStatusMessage('Votre message a été envoyé avec succès.');
      setFormState({ firstName: '', lastName: '', email: '', phone: '', message: '' }); // Reset form
    }, (err) => {
      console.log('FAILED...', err);
      setStatusMessage('Une erreur s\'est produite, veuillez réessayer.');
    });
  };

  return (
    <div className="contact-container">
      <div className="contact-header">
        <div className="contact-info">
          <div className="contact-card">
            <i className="fas fa-phone-alt"></i>
            <p>Si vous avez besoin d'un accompagnement dans vos projets immobiliers, appelez maintenant.</p>
            <a href="tel:+221331000000">+221 33 100 00 00</a>
          </div>
          <div className="contact-card">
            <i className="fas fa-map-marker-alt"></i>
            <p>Nous sommes ouverts du Lundi au Vendredi 9h - 17h</p>
            <p>Randoulène Sud - Angle Serigne Fallou – Thiès - Sénégal</p>
            <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer">Consulter la carte</a>
          </div>
          <div className="contact-card">
            <i className="fas fa-envelope"></i>
            <p>Contactez-nous par email.</p>
            <a href="mailto:mentorservice@gmail.com">Envoyer un message</a>
          </div>
          <div className="contact-card">
            <i className="fab fa-whatsapp"></i>
            <p>Contactez-nous directement via WhatsApp.</p>
            <a href="https://wa.me/221331000000?text=Bonjour,%20je%20souhaite%20avoir%20des%20informations%20sur%20vos%20services%20immobiliers." target="_blank" rel="noopener noreferrer">Envoyer un message</a>
          </div>
        </div>
      </div>
      
      <div className="contact-form-container">
        <h2>Vous êtes à la recherche d'une location ou d'un bien immobilier à vendre ? Vous êtes propriétaire et souhaitez louer ?</h2>
        <p>Vous pouvez compter sur le dynamisme de nos agents pour trouver efficacement des solutions à vos recherches.</p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input type="text" name="firstName" placeholder="Prénom" value={formState.firstName} onChange={handleChange} required />
          <input type="text" name="lastName" placeholder="Nom" value={formState.lastName} onChange={handleChange} required />
          <input type="email" name="email" placeholder="Email" value={formState.email} onChange={handleChange} required />
          <input type="tel" name="phone" placeholder="Téléphone" value={formState.phone} onChange={handleChange} required />
          <textarea name="message" placeholder="Message" value={formState.message} onChange={handleChange} required></textarea>
          <button type="submit">ENVOYER</button>
        </form>
        {statusMessage && <p className="status-message">{statusMessage}</p>}
      </div>
    </div>
  );
}

export default Contact;
