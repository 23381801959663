import React from 'react';
import './Footer.css'; // Nous ajouterons le style plus tard

function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <p>Copyright &copy; {currentYear} MBS Immo. Tous droits réservés. Version 1.0</p>
        </div>
        <div className="footer-right">
          <p>Suivez-nous :</p>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a> | 
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"> Twitter</a> |
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"> LinkedIn</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
