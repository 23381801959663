import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './SupprimerPage.css'; // Importer le CSS spécifique à cette page

function SupprimerPage({ locations, onDeleteLocation }) {
  const { id } = useParams();
  const navigate = useNavigate();

  // Trouver la location à supprimer en utilisant l'ID
  const locationToDelete = locations.find(location => location._id === id);

  const handleDelete = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
      try {
        // Supprimer la propriété de la base de données via un appel API
        await axios.delete(`http://localhost:5000/api/properties/${id}`);
        onDeleteLocation(id); // Mettre à jour l'état local après suppression
        alert('L\'élément a été supprimé avec succès !');
        navigate('/admin/supprimer'); // Rediriger vers la page de suppression
        // Recharger la page pour afficher les changements
        window.location.reload();
      } catch (error) {
        console.error('Erreur lors de la suppression de la propriété:', error);
        alert('Erreur lors de la suppression de la propriété.');
      }
    }
  };

  const handleCancel = () => {
    navigate('/admin/supprimer'); // Rediriger vers la page de suppression sans supprimer
  };

  if (!locationToDelete) {
    return <p>Aucune location trouvée pour l'ID spécifié.</p>;
  }

  return (
    <div className="supprimer-page-container">
      <h1>Supprimer la location</h1>
      <div className="location-details-supprimer">
        <p><strong>ID : </strong>{locationToDelete._id}</p>
        <p><strong>Référence : </strong>{locationToDelete.details.reference}</p>
      </div>
      <div className="delete-actions-supprimer">
        <button className="btn-delete" onClick={handleDelete}>Supprimer</button>
        <button className="btn-cancel" onClick={handleCancel}>Annuler</button>
      </div>
    </div>
  );
}

export default SupprimerPage;
