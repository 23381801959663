// EmailSent.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './EmailSent.css';

function EmailSent() {
  const location = useLocation();
  const navigate = useNavigate();
  const message = location.state?.message || "Votre inscription est presque terminée ! Veuillez vérifier votre e-mail pour finaliser votre inscription.";

  return (
    <div className="email-sent-container">
      <div className="email-sent-box">
        <h2>Inscription réussie</h2>
        <p>{message}</p>
        <button onClick={() => navigate('/')}>Retour à l'accueil</button>
      </div>
    </div>
  );
}

export default EmailSent;
