import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ModifierPage.css'; // Le CSS spécifique pour la page de modification

function ModifierPage({ locations, onUpdateLocation }) {
  const { id } = useParams();
  const navigate = useNavigate();

  // Trouver l'élément de location à modifier
  const locationToEdit = locations.find(loc => loc._id === id);

  // Si l'élément n'est pas trouvé, initialiser un état vide par défaut
  const [updatedLocation, setUpdatedLocation] = useState(locationToEdit || {
    title: '',
    localisation: '',
    description: '',
    regulation: '',
    rooms: 0,
    bathrooms: 0,
    bed: 0,
    lounge: 0,
    availability: 'Disponible',
    category: '',
    images: [],  // Ici les images existantes
    details: {
      reference: '',
      city: '',
      district: '',
      name: '',
      country: '',
      price: '',
    },
    amenities: [],
  });

  useEffect(() => {
    if (locationToEdit) {
      setUpdatedLocation(locationToEdit);
    }
  }, [locationToEdit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedLocation({
      ...updatedLocation,
      [name]: value,
    });
  };

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setUpdatedLocation({
      ...updatedLocation,
      details: {
        ...updatedLocation.details,
        [name]: value,
      }
    });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImagePaths = files.map(file => `./images/${file.name}`); // Utiliser les noms de fichiers pour créer des chemins relatifs
    setUpdatedLocation({
      ...updatedLocation,
      images: [...updatedLocation.images, ...newImagePaths], // Concaténer les nouvelles images aux existantes
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (locationToEdit) {
      await onUpdateLocation(updatedLocation); // Appelle la fonction pour mettre à jour la location
      alert('L\'élément a été modifié avec succès !');
      navigate('/admin/modifier'); // Redirige vers la liste des locations après modification
    } else {
      alert('Aucun élément à modifier trouvé.');
    }
  };

  return (
    <div className="modifier-page-container">
      <h1>Modifier la location</h1>
      <form onSubmit={handleSubmit}>
        <label>Titre :</label>
        <input type="text" name="title" value={updatedLocation.title} onChange={handleInputChange} required />

        <label>Référence :</label>
        <input type="text" name="reference" value={updatedLocation.details.reference} onChange={handleDetailsChange} required />

        <label>Localisation :</label>
        <input type="text" name="localisation" value={updatedLocation.localisation} onChange={handleInputChange} required />

        <label>Prix :</label>
        <input type="text" name="price" value={updatedLocation.details.price} onChange={handleDetailsChange} required />

        <label>Description :</label>
        <textarea name="description" value={updatedLocation.description} onChange={handleInputChange} required />

        <label>Réglementation :</label>
        <textarea name="regulation" value={updatedLocation.regulation} onChange={handleInputChange} required />

        <label>Chambres :</label>
        <input type="number" name="rooms" value={updatedLocation.rooms} onChange={handleInputChange} required />

        <label>Salles de bains :</label>
        <input type="number" name="bathrooms" value={updatedLocation.bathrooms} onChange={handleInputChange} required />

        <label>Lits :</label>
        <input type="number" name="bed" value={updatedLocation.bed} onChange={handleInputChange} required />

        <label>Salons :</label>
        <input type="number" name="lounge" value={updatedLocation.lounge} onChange={handleInputChange} required />

        <label>Catégorie :</label>
        <input type="text" name="category" value={updatedLocation.category} onChange={handleInputChange} required />

        <label>Ville :</label>
        <input type="text" name="city" value={updatedLocation.details.city} onChange={handleDetailsChange} required />

        <label>Quartier :</label>
        <input type="text" name="district" value={updatedLocation.details.district} onChange={handleDetailsChange} required />

        <label>Libellé :</label>
        <input type="text" name="name" value={updatedLocation.details.name} onChange={handleDetailsChange} />

        <label>Pays :</label>
        <input type="text" name="country" value={updatedLocation.details.country} onChange={handleDetailsChange} required />

        <label>Installations & Commodités (séparées par une virgule) :</label>
        <input type="text" name="amenities" value={updatedLocation.amenities.join(', ')} onChange={(e) => setUpdatedLocation({ ...updatedLocation, amenities: e.target.value.split(',').map(a => a.trim()) })} />

        <label>Images :</label>
        <input type="file" multiple onChange={handleImageChange} />

        <button type="submit">Modifier</button>
      </form>
    </div>
  );
}

export default ModifierPage;
