// Profile.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Profile.css'; // Ajoutez un fichier CSS pour le style

function Profile({ userEmail }) {
  const [userInfo, setUserInfo] = useState({
    name: '',
    surname: '',
    email: '',
    phoneNumber: '',
    gender: '',
    birthdate: '',
    residence: '',
  });
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [newGender, setNewGender] = useState('');
  const [newBirthdate, setNewBirthdate] = useState('');
  const [newResidence, setNewResidence] = useState('');
  const [message, setMessage] = useState('');

  // Récupérer les informations de l'utilisateur lors du chargement du composant
  useEffect(() => {
    // Récupérer les informations de l'utilisateur à partir de l'API
    const fetchUserInfo = async () => {
      try {
        // Récupérer le token JWT depuis le localStorage
        const token = localStorage.getItem('authToken');
        
        // Configuration des headers pour inclure le token JWT
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `https://booking-backend-3897450fe5a2.herokuapp.com/api/users/profile?email=${userEmail}`,
          config
        );
        setUserInfo(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des informations utilisateur:', error);
      }
    };

    fetchUserInfo();
  }, [userEmail]);

  const handleUpdateProfile = async () => {
    const updatedFields = { email: userInfo.email }; // Inclure toujours l'email pour l'identification

    // Ajouter les champs modifiés uniquement
    if (newPhoneNumber) updatedFields.phoneNumber = newPhoneNumber;
    if (newGender) updatedFields.gender = newGender;
    if (newBirthdate) updatedFields.birthdate = newBirthdate;
    if (newResidence) updatedFields.residence = newResidence;
    
    try {
      // Récupérer le token JWT depuis le localStorage
      const token = localStorage.getItem('authToken');

      // Configuration des headers pour inclure le token JWT
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.put(
        'https://booking-backend-3897450fe5a2.herokuapp.com/api/users/profile',
        updatedFields,
        config
      );

      setMessage(response.data.message || 'Profil mis à jour avec succès.');

      // Mettre à jour uniquement les champs qui ont été modifiés dans l'état local
      setUserInfo({
        ...userInfo,
        phoneNumber: newPhoneNumber || userInfo.phoneNumber,
        gender: newGender || userInfo.gender,
        birthdate: newBirthdate || userInfo.birthdate,
        residence: newResidence || userInfo.residence,
      });

      setNewPhoneNumber('');
      setNewGender('');
      setNewBirthdate('');
      setNewResidence('');
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
      setMessage('Erreur lors de la mise à jour du profil.');
    }
  };

  return (
    <div className="profile-container">
      <h2>Mon Profil</h2>
      <div className="profile-info-grid">
        <div><strong>Nom :</strong> {userInfo.name}</div>
        <div><strong>Prénom :</strong> {userInfo.surname}</div>
        <div><strong>Email :</strong> {userInfo.email}</div>
        <div><strong>Numéro de téléphone :</strong> {userInfo.phoneNumber || 'Non spécifié'}</div>
        <div><strong>Sexe :</strong> {userInfo.gender || 'Non spécifié'}</div>
        <div><strong>Date de naissance :</strong> {userInfo.birthdate ? new Date(userInfo.birthdate).toLocaleDateString() : 'Non spécifiée'}</div>
        <div><strong>Lieu de résidence :</strong> {userInfo.residence || 'Non spécifié'}</div>
      </div>
      <div className="profile-update">
        <h3>Mettre à jour vos informations personnelles :</h3>
        <input 
          type="text"
          placeholder="Nouveau numéro de téléphone"
          value={newPhoneNumber}
          onChange={(e) => setNewPhoneNumber(e.target.value)}
        />
        <select value={newGender} onChange={(e) => setNewGender(e.target.value)}>
          <option value="">Sélectionnez le sexe</option>
          <option value="Masculin">Masculin</option>
          <option value="Féminin">Féminin</option>
        </select>
        <input 
          type="date"
          placeholder="Date de naissance"
          value={newBirthdate}
          onChange={(e) => setNewBirthdate(e.target.value)}
        />
        <input 
          type="text"
          placeholder="Lieu de résidence"
          value={newResidence}
          onChange={(e) => setNewResidence(e.target.value)}
        />
        {message && <p className="message">{message}</p>}
        <button onClick={handleUpdateProfile}>Mettre à jour</button>
      </div>
    </div>
  );
}

export default Profile;
