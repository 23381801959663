import React from 'react';
import './Gerance.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faWrench, faHandshake, faFileContract, faDollarSign, faBullhorn, faGavel, faCheckSquare } from '@fortawesome/free-solid-svg-icons';

//import contactSupportImage from '../../images/contact-support.png';
//import gestionBienImage from '../../images/gestionbien.jpg';

function Gerance() {
  return (
    <div className="service-page">
      <div className="gerance-header">
        <h1>Gérance Immobilière</h1>
        <p>Optimisez la gestion de vos biens immobiliers avec un partenaire de confiance.</p>
      </div>

      <div className="gerance-content">
        <div className="gerance-text">
          <h2>Pourquoi choisir Mentor Business Services pour la gérance de vos biens ?</h2>
          <p>
            Chez <strong>Mentor Business Services</strong>, nous comprenons l'importance de maximiser le rendement de vos investissements immobiliers tout en minimisant les tracas quotidiens liés à la gestion des biens. Nos services de gérance sont conçus pour vous offrir une tranquillité d'esprit totale, en prenant en charge tous les aspects de la gestion locative.
          </p>

          <h3>Nos Services de Gérance</h3>
          <div className="service-list">
            <div className="service-item-gerance">
              <FontAwesomeIcon icon={faKey} className="service-icon-gerance" />
              <div>
                <h4>Gestion Locative</h4>
                <p>Nous gérons tous les aspects de la location de vos biens, de la sélection des locataires à la perception des loyers, en passant par la rédaction des baux.</p>
              </div>
            </div>
            <div className="service-item-gerance">
              <FontAwesomeIcon icon={faWrench} className="service-icon-gerance" />
              <div>
                <h4>Maintenance et Réparations</h4>
                <p>Nous nous occupons de l'entretien régulier et des réparations nécessaires pour assurer la durabilité et la valeur de votre bien.</p>
              </div>
            </div>
            <div className="service-item-gerance">
              <FontAwesomeIcon icon={faHandshake} className="service-icon-gerance" />
              <div>
                <h4>Gestion des Relations Locataires</h4>
                <p>Nous servons d'intermédiaire entre vous et vos locataires, gérant les communications, les plaintes, et les demandes de services.</p>
              </div>
            </div>
            <div className="service-item-gerance">
              <FontAwesomeIcon icon={faFileContract} className="service-icon-gerance" />
              <div>
                <h4>Suivi Comptable et Reporting</h4>
                <p>Nous vous fournissons des rapports financiers réguliers et transparents sur les performances de vos biens.</p>
              </div>
            </div>
          </div>

          <h2>Service de Location</h2>
          <div className="service-list">
            <div className="service-item-gerance">
              <FontAwesomeIcon icon={faDollarSign} className="service-icon-gerance" />
              <div>
                <h4>Sélection des Locataires</h4>
                <p>Nous effectuons une sélection rigoureuse des locataires potentiels pour assurer que votre bien soit entre de bonnes mains.</p>
              </div>
            </div>
            <div className="service-item-gerance">
              <FontAwesomeIcon icon={faBullhorn} className="service-icon-gerance" />
              <div>
                <h4>Promotion de Votre Bien</h4>
                <p>Nous utilisons les meilleures stratégies de marketing pour assurer une visibilité maximale à votre bien.</p>
              </div>
            </div>
            <div className="service-item-gerance">
              <FontAwesomeIcon icon={faCheckSquare} className="service-icon-gerance" />
              <div>
                <h4>Négociation et Signature des Baux</h4>
                <p>Nous gérons toutes les négociations contractuelles et nous nous assurons que les baux sont signés conformément aux lois en vigueur.</p>
              </div>
            </div>
            <div className="service-item-gerance">
              <FontAwesomeIcon icon={faFileContract} className="service-icon-gerance" />
              <div>
                <h4>Suivi des Loyers</h4>
                <p>Nous veillons à ce que les loyers soient perçus à temps et nous gérons les éventuels retards de paiement.</p>
              </div>
            </div>
          </div>

          <h2>Service de Vente</h2>
          <div className="service-list">
            <div className="service-item-gerance">
              <FontAwesomeIcon icon={faCheckSquare} className="service-icon-gerance" />
              <div>
                <h4>Évaluation Précise</h4>
                <p>Nos experts réalisent une évaluation détaillée de votre bien pour déterminer le meilleur prix de vente.</p>
              </div>
            </div>
            <div className="service-item-gerance">
              <FontAwesomeIcon icon={faBullhorn} className="service-icon-gerance" />
              <div>
                <h4>Marketing et Promotion</h4>
                <p>Nous élaborons des stratégies de marketing personnalisées pour augmenter la visibilité de votre bien.</p>
              </div>
            </div>
            <div className="service-item-gerance">
              <FontAwesomeIcon icon={faGavel} className="service-icon-gerance" />
              <div>
                <h4>Gestion des Négociations</h4>
                <p>Nous gérons les négociations avec les acheteurs potentiels pour obtenir les meilleures conditions de vente possibles.</p>
              </div>
            </div>
            <div className="service-item-gerance">
              <FontAwesomeIcon icon={faFileContract} className="service-icon-gerance" />
              <div>
                <h4>Assistance Juridique</h4>
                <p>Notre équipe assure la gestion des aspects juridiques de la transaction, garantissant que tous les documents sont en ordre.</p>
              </div>
            </div>
          </div>

          <h3>Nos Zones d'Intervention</h3>
          <p>
            Nous opérons principalement à Dakar et Thiès, mais nos services de gérance, de location, et de vente sont disponibles dans tout le Sénégal. Notre connaissance approfondie du marché local nous permet de vous offrir des conseils stratégiques adaptés à chaque région, assurant ainsi le succès de vos transactions immobilières.
          </p>
        </div>

        <div className="gerance-images">
          <div className="image-card">
            <img src="/images/contact-support.png" alt="Contactez-nous" />
            <a href="/contact" className="image-link-gerance">Contactez-nous</a>
          </div>
          <div className="image-card">
            <img src="/images/gestionbien.jpg" alt="Gérez votre bien" />
            <a href="/gerance/formulaire" className="image-link-gerance">Confiez-nous votre bien</a>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Gerance;
