// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Styles mobiles pour les petits écrans */
@media (max-width: 768px) {
  body {
    margin: 0;
    padding: 10px; /* Ajout d'un léger padding pour éviter que le contenu soit trop collé aux bords */
    font-size: 14px; /* Réduction de la taille de la police pour les petits écrans */
    line-height: 1.5; /* Amélioration de la lisibilité */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
    font-size: 13px; /* Réduction de la taille des polices monospace sur mobile */
    word-wrap: break-word; /* Assure que le code ne déborde pas sur les petits écrans */
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;;AAGA,0CAA0C;AAC1C;EACE;IACE,SAAS;IACT,aAAa,EAAE,kFAAkF;IACjG,eAAe,EAAE,+DAA+D;IAChF,gBAAgB,EAAE,kCAAkC;IACpD;;gBAEY;IACZ,mCAAmC;IACnC,kCAAkC;EACpC;;EAEA;IACE;eACW;IACX,eAAe,EAAE,4DAA4D;IAC7E,qBAAqB,EAAE,4DAA4D;EACrF;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n/* Styles mobiles pour les petits écrans */\n@media (max-width: 768px) {\n  body {\n    margin: 0;\n    padding: 10px; /* Ajout d'un léger padding pour éviter que le contenu soit trop collé aux bords */\n    font-size: 14px; /* Réduction de la taille de la police pour les petits écrans */\n    line-height: 1.5; /* Amélioration de la lisibilité */\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n      sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n  }\n\n  code {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n      monospace;\n    font-size: 13px; /* Réduction de la taille des polices monospace sur mobile */\n    word-wrap: break-word; /* Assure que le code ne déborde pas sur les petits écrans */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
