import React from 'react';
import './BTP.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram, faHardHat, faShieldAlt, faLightbulb } from '@fortawesome/free-solid-svg-icons';

//import contactSupportImage from '../../images/contact-support.png';
//import gestionBienImage from '../../images/gestionbien.jpg';

function BTP() {
  return (
    <div className="service-page">
      <div className="btp-header">
        <h1>Services BTP</h1>
        <p>Assurez la réussite de vos projets de construction avec nos services experts en BTP.</p>
      </div>

      <div className="btp-content">
        <div className="btp-text">
          <h2>Nos Prestations en BTP</h2>
          <p>
            Chez <strong>Mentor Business Services</strong>, nous mettons à votre disposition notre expertise en Bâtiment et Travaux Publics (BTP) pour mener à bien vos projets de construction et de rénovation. Nous veillons à ce que chaque projet soit exécuté avec un niveau élevé de professionnalisme, en respectant les délais, les coûts, et surtout, les normes de qualité et de sécurité.
          </p>

          <h3>Nos Services en Détail</h3>
          <div className="service-list">
            <div className="service-item-btp">
              <FontAwesomeIcon icon={faProjectDiagram} className="service-icon-btp" />
              <div>
                <h4>Gestion de Projet</h4>
                <p>Nous assurons une gestion rigoureuse de vos projets, de la planification à la réalisation, en passant par la coordination des différents intervenants.</p>
              </div>
            </div>
            <div className="service-item-btp">
              <FontAwesomeIcon icon={faHardHat} className="service-icon-btp" />
              <div>
                <h4>Supervision des Travaux</h4>
                <p>Nous surveillons chaque étape des travaux pour garantir que tout se déroule conformément aux plans et aux spécifications techniques.</p>
              </div>
            </div>
            <div className="service-item-btp">
              <FontAwesomeIcon icon={faShieldAlt} className="service-icon-btp" />
              <div>
                <h4>Respect des Normes</h4>
                <p>Nous veillons au respect des normes de qualité, de sécurité et environnementales en vigueur, pour assurer la pérennité et la conformité de vos ouvrages.</p>
              </div>
            </div>
            <div className="service-item-btp">
              <FontAwesomeIcon icon={faLightbulb} className="service-icon-btp" />
              <div>
                <h4>Conseil et Expertise</h4>
                <p>Nous vous accompagnons avec des conseils techniques et stratégiques tout au long de votre projet, pour vous aider à prendre les meilleures décisions.</p>
              </div>
            </div>
          </div>

          <h3>Nos Zones d'Intervention</h3>
          <p>
            Nos services BTP couvrent principalement les régions de Dakar et Thiès, mais nous sommes également disponibles pour intervenir sur des projets à travers tout le Sénégal.
          </p>
        </div>

        <div className="btp-images">
          <div className="image-card">
            <img src="/images/contact-support.png" alt="Contactez-nous" />
            <a href="/contact" className="image-link-btp">Contactez-nous</a>
          </div>
          <div className="image-card">
            <img src="/images/gestionbien.jpg" alt="Gérez votre bien" />
            <a href="/gerance/formulaire" className="image-link-btp">Confiez-nous votre bien</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BTP;
