import React from 'react';
import './Presentation.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faKey, faHome, faChartLine, faComments, faTools } from '@fortawesome/free-solid-svg-icons';


function Presentation() {
  return (
    <div className="presentation-container">
      <div className="header-banner">
        <h1>Bienvenue chez MBS : Mentor Business Services</h1>
        <p>Votre partenaire immobilier de confiance à Dakar et Thiès</p>
      </div>

      <div className="section about-us">
        <h2>À propos de nous</h2>
        <p>
          Mentor Business Services est une agence immobilière basée à Thiès, au Sénégal. Nous
          sommes spécialisés dans la gérance, les locations, les ventes, ainsi que dans les études et expertises, consultations et BTP. Notre équipe
          dévouée est prête à vous accompagner dans tous vos projets immobiliers
          à Dakar et Thiès.
        </p>
      </div>

      <div className="section services">
        <h2>Nos Services</h2>
        <div className="services-grid">
          <div className="service-card">
            <FontAwesomeIcon icon={faBuilding} className="service-icon-presentation" />
            <h3>Gérance</h3>
            <p>
              Nous assurons la gestion complète de vos biens immobiliers avec
              professionnalisme et transparence.
            </p>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faKey} className="service-icon-presentation" />
            <h3>Locations</h3>
            <p>
              Que vous soyez locataire ou propriétaire, nous vous aidons à
              trouver ou à louer des biens de qualité.
            </p>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faHome} className="service-icon-presentation" />
            <h3>Ventes</h3>
            <p>
              Nous vous accompagnons dans l'achat ou la vente de vos biens
              immobiliers, avec un service personnalisé.
            </p>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faChartLine} className="service-icon-presentation" />
            <h3>Études & Expertises</h3>
            <p>
              Nos experts réalisent des études approfondies et des expertises
              pour garantir la valeur de votre bien.
            </p>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faComments} className="service-icon-presentation" />
            <h3>Consultations</h3>
            <p>
              Nous offrons des services de consultation pour vous guider dans
              vos décisions immobilières.
            </p>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faTools} className="service-icon-presentation" />
            <h3>BTP</h3>
            <p>
              Notre équipe de BTP est à votre service pour tous vos projets de
              construction et de rénovation.
            </p>
          </div>
        </div>
      </div>

      <div className="section locations">
        <h2>Nos Emplacements</h2>
        <p>
          Nous sommes présents à Dakar et Thiès pour répondre à tous vos besoins
          immobiliers.
        </p>
      </div>

      <div className="section contact">
        <h2>Contactez-nous</h2>
        <p>
          N'hésitez pas à nous contacter pour toute question ou pour discuter de
          vos projets immobiliers. Notre équipe est à votre écoute.
        </p>
        <a href="/contact" className="contact-button">Contactez-nous</a>
      </div>
    </div>
  );
}

export default Presentation;
