import React from 'react';

function Ventes() {
  return (
    <div>
      <h1>Ventes</h1>
      <p>Ceci est la page de gestion des ventes.</p>
    </div>
  );
}

export default Ventes;
