import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import './Locations.css'; // Le CSS pour styliser les cartes
import { Link } from 'react-router-dom';

function Chambres({ locations, page, onPageChange, onCategoryChange, onLocalisationChange, onMinPriceChange, onMaxPriceChange, minPrice, maxPrice, category, localisation, onSortChange, onSearchChange, onSearchClick, itemsPerPage = 10 }) {
  
  const totalLocations = locations.filter(location => location.category === 'Studios' || location.category === 'Chambres').length;
  const totalPages = Math.ceil(totalLocations / itemsPerPage);
  
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentLocations = locations
    .filter(location => location.category === 'Studios' || location.category === 'Chambres')
    .slice(startIndex, endIndex);

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button 
          key={i} 
          onClick={() => onPageChange(i)} 
          disabled={i === page}
          className={`pagination-number ${i === page ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination-container">
        {/* Bouton pour la première page */}
        <button
          className="pagination-btn"
          onClick={() => onPageChange(1)}
          disabled={page === 1}
        >
          &#171; {/* Guillemet double pointant vers la gauche */}
        </button>

        {/* Bouton pour la page précédente */}
        <button
          className="pagination-btn"
          onClick={() => onPageChange(page - 1)}
          disabled={page === 1}
        >
          &#8249; {/* Flèche simple pointant vers la gauche */}
        </button>

        {/* Numéros de page */}
        {pages}

        {/* Bouton pour la page suivante */}
        <button
          className="pagination-btn"
          onClick={() => onPageChange(page + 1)}
          disabled={page === totalPages}
        >
          &#8250; {/* Flèche simple pointant vers la droite */}
        </button>

        {/* Bouton pour la dernière page */}
        <button
          className="pagination-btn"
          onClick={() => onPageChange(totalPages)}
          disabled={page === totalPages}
        >
          &#187; {/* Guillemet double pointant vers la droite */}
        </button>
      </div>
    );
  };

  return (
    <div className="locations-container">
      {/* Filtres */}
      {/* Filtres */}
      <div className="filters-container">
        <div className="filter-item">
          <input
            type="text"
            placeholder="Localisation"
            value={localisation}
            onChange={(e) => onLocalisationChange(e.target.value)}
          />
        </div>
        {/* Filtre pour le tri des prix */}
        <div className="filter-item">
          <select onChange={(e) => onSortChange(e.target.value)}>
            <option value="">Trier par prix</option>
            <option value="asc">Prix croissant</option>
            <option value="desc">Prix décroissant</option>
          </select>
        </div>
        <div className="filter-item">
          <input
            type="number"
            placeholder="Prix max"
            value={maxPrice}
            onChange={(e) => onMaxPriceChange(e.target.value)}
          />
        </div>
        <div className="filter-item">
          <input
            type="text"
            placeholder="Rechercher..."
            onChange={(e) => onSearchChange(e.target.value)} // Capturez la saisie utilisateur
          />
        </div>
        <div className="filter-item">
          <button className="btn-search" onClick={onSearchClick}>Rechercher</button>
        </div>
      </div>

      {/* Liste des propriétés */}
      {currentLocations && currentLocations.length > 0 ? (
        currentLocations.map(location => (
          <div className="location-card" key={location._id}>
              <div className="location-image">
                <Splide
                  options={{
                    type: 'loop',
                    perPage: 1,
                    pagination: true,
                    autoplay: true,
                    pauseOnHover: true,
                    arrows: true,
                    interval: 3000,
                    speed: 1000,
                  }}
                  className="splide-carousel"
                >
                  {location.images.map((image, index) => (
                    <SplideSlide key={index}>
                      <Link to={`/detail/${location._id}`}>
                        <img src={image} alt={`Slide ${index + 1}`} />
                      </Link>
                    </SplideSlide>
                  ))}
                </Splide>
              </div>
            <div className="location-details-l">
              <Link to={`/detail/${location._id}`} key={location._id}>
                <h2>{location.title}</h2>
              </Link>
              <p className="location-icon-l">
                <i className="fas fa-map-marker-alt"></i> {location.localisation}
              </p>
              <p className="location-category-l">{location.category}
                <span className="location-features">
                  <i className="fas fa-bed"></i> {location.rooms} chambres
                  <i className="fas fa-bath"></i> {location.bathrooms} salles de bains
                  <i className="fas fa-couch"></i> {location.bed} lits
                  <i className="fas fa-chair"></i> {location.lounge} salons
                </span>
              </p>
              <p className="location-description">{location.description}</p>
              <div className="location-actions-price">
                <div className="location-actions">
                  <button 
                    className="btn-call" 
                    onClick={() => {
                      window.location.href = "https://wa.me/221771006477";
                    }}
                  >
                    Réserver
                  </button>
                  <Link to="/contact">
                    <button className="btn-contact">Contactez</button>
                  </Link>
                </div>
                <div className="location-price-container">
                  <p className="location-price">
                    {/* Formatage du prix avec séparateurs de milliers et ajout de l'unité */}
                    {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(location.details.price)} par {location.details.sequence}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>Aucune location disponible pour le moment.</p>
      )}
      {/* Pagination */}
      {renderPagination()}
    </div>
  );
}

export default Chambres;
