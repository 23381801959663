import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Ajouter.css';

import axios from 'axios';


function Ajouter({ onAddLocation }) {
  const navigate = useNavigate();
  
  const [newLocation, setNewLocation] = useState({
    title: '',
    localisation: '',
    description: '',
    regulation: '',
    rooms: 0,
    bathrooms: 0,
    bed: 0,
    lounge: 0,
    availability: 'Disponible',
    category: '',
    images: [], // Stockera les noms des fichiers images
    details: {
      reference: '',
      city: '',
      district: '',
      name: '',
      country: 'Sénégal',
      price: '',
    },
    amenities: [],
  });

  const [selectedFiles, setSelectedFiles] = useState([]); // Pour stocker les images sélectionnées

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLocation({
      ...newLocation,
      [name]: value,
    });
  };

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setNewLocation({
      ...newLocation,
      details: {
        ...newLocation.details,
        [name]: value,
      }
    });
  };

  // Cette fonction va ajouter les noms des fichiers d'image
  /*const handleImageChange = (e) => {
    const files = Array.from(e.target.files).map(file => `./images/${file.name}`); // Récupérer le nom du fichier et créer un chemin relatif à partir de public/images
    setNewLocation({
      ...newLocation,
      images: files, // Ajouter les chemins relatifs des images dans l'état
    });
  };*/
  // Gestion des images sélectionnées
  const handleImageChange = (e) => {
    setSelectedFiles(e.target.files); // Stocker les fichiers sélectionnés
  };

  // Fonction pour l'envoi des images au serveur
  const uploadImages = async () => {
    const formData = new FormData();
    Array.from(selectedFiles).forEach(file => {
      formData.append('images', file); // Ajouter chaque fichier au FormData
    });

    try {
      const response = await axios.post('http://localhost:5000/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.filePaths; // Retourner les chemins des fichiers sauvegardés
    } catch (error) {
      console.error("Erreur lors de l'upload des images :", error);
      alert("Une erreur est survenue lors de l'upload des images.");
      throw error;
    }
  };


  /*const handleSubmit = async (e) => {
    e.preventDefault();

    const propertyData = {
      ...newLocation,
      amenities: newLocation.amenities.join(',').split(','), // Convertir en tableau
    };

    try {
      const response = await axios.post('http://localhost:5000/api/properties', propertyData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      alert("L'élément a été ajouté avec succès !");
      onAddLocation(response.data); // Appelle la fonction parent pour mettre à jour la liste des locations
      navigate('/locations');
    } catch (error) {
      console.error("Erreur lors de l'ajout de la propriété :", error);
      alert("Une erreur est survenue lors de l'ajout de la propriété.");
    }
  };*/
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Étape 1 : Uploader les images et récupérer leurs chemins
      const imagePaths = await uploadImages();

      // Étape 2 : Créer les données de la propriété avec les chemins d'images
      const propertyData = {
        ...newLocation,
        images: imagePaths, // Utiliser les chemins des images uploadées
        amenities: newLocation.amenities.join(',').split(','), // Convertir en tableau
      };

      // Étape 3 : Envoyer les données de la nouvelle propriété à l'API
      const response = await axios.post('http://localhost:5000/api/properties', propertyData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      alert("L'élément a été ajouté avec succès !");
      onAddLocation(response.data); // Appeler la fonction parent pour mettre à jour la liste des locations
      navigate('/locations');
    } catch (error) {
      console.error("Erreur lors de l'ajout de la propriété :", error);
      alert("Une erreur est survenue lors de l'ajout de la propriété.");
    }
  };
   
  

  return (
    <div className="ajouter-container">
      <h1>Ajouter une nouvelle location</h1>
      <form onSubmit={handleSubmit}>

        <label>Titre :</label>
        <input type="text" name="title" placeholder="Ex : Appartement F3 très classe à louer au Point E" value={newLocation.title} onChange={handleInputChange} required />

        <label>Référence :</label>
        <input type="text" name="reference" placeholder="La référence de l'élément." value={newLocation.details.reference} onChange={handleDetailsChange} required />

        <label>Localisation :</label>
        <input type="text" name="localisation" placeholder="Ex : Point E, Dakar ou Grand Standing, Thiès" value={newLocation.localisation} onChange={handleInputChange} required />

        <label>Prix :</label>
        <input type="text" name="price" placeholder="Ex : 15 000 FCFA / Nuit, 50 000 FCFA / Mois, 30 000 FCFA / Semaines" value={newLocation.details.price} onChange={handleDetailsChange} required />

        <label>Description :</label>
        <textarea name="description" placeholder="Toutes les informations nécessaires et utiles." value={newLocation.description} onChange={handleInputChange} required />

        <label>Règlement intérieur :</label>
        <textarea name="regulation" placeholder="Le règlement intérieur." value={newLocation.regulation} onChange={handleInputChange} required />

        <label>Chambres :</label>
        <input type="number" name="rooms" value={newLocation.rooms} onChange={handleInputChange} required />

        <label>Salles de bains :</label>
        <input type="number" name="bathrooms" value={newLocation.bathrooms} onChange={handleInputChange} required />

        <label>Salons :</label>
        <input type="number" name="lounge" value={newLocation.lounge} onChange={handleInputChange} required />

        <label>Lits :</label>
        <input type="number" name="bed" value={newLocation.bed} onChange={handleInputChange} required />

        <label>Catégorie :</label>
        <input type="text" name="category" placeholder="Ex : Meublé, Location, Vente, etc" value={newLocation.category} onChange={handleInputChange} required />

        <label>Ville :</label>
        <input type="text" name="city" placeholder="Ex : Dakar, Thiès" value={newLocation.details.city} onChange={handleDetailsChange} required />

        <label>Quartier :</label>
        <input type="text" name="district" placeholder="Ex : Point E" value={newLocation.details.district} onChange={handleDetailsChange} required />

        <label>Libellé :</label>
        <input type="text" name="name" placeholder="Ex : Rond Point Mbaw ou Près de telle" value={newLocation.details.name} onChange={handleDetailsChange} />

        <label>Pays :</label>
        <input type="text" name="country" value={newLocation.details.country} onChange={handleDetailsChange} required />

        <label>Installations & Commodités (séparées par une virgule) :</label>
        <input type="text" name="amenities" placeholder="Ex : 'Ascenseur', 'Caméra de surveillance', 'Climatisation', 'Groupe électrogène', 'Parking', 'etc'" value={newLocation.amenities.join(', ')} onChange={(e) => setNewLocation({ ...newLocation, amenities: e.target.value.split(',').map(a => a.trim()) })} />

        <label>Images :</label>
        <input type="file" multiple onChange={handleImageChange} />

        <button type="submit">Ajouter</button>
      </form>
    </div>
  );
}

export default Ajouter;
