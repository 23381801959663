import React from 'react';

function Terrains() {
  return (
    <div>
      <h1>Terrains</h1>
      <p>Ceci est la page des terrains disponibles à la vente.</p>
    </div>
  );
}

export default Terrains;
