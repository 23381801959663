import React from 'react';
import './Consultances.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faLightbulb, faCog, faHandsHelping } from '@fortawesome/free-solid-svg-icons';

//import contactSupportImage from '../../images/contact-support.png';
//import gestionBienImage from '../../images/gestionbien.jpg';

function Consultances() {
  return (
    <div className="service-page">
      <div className="consultances-header">
        <h1>Consultances Immobilières</h1>
        <p>Optimisez vos investissements immobiliers avec nos conseils stratégiques et personnalisés.</p>
      </div>

      <div className="consultances-content">
        <div className="consultances-text">
          <h2>Pourquoi choisir nos services de consultance ?</h2>
          <p>
            Chez <strong>Mentor Business Services</strong>, nous offrons une expertise pointue pour vous aider à maximiser le potentiel de vos biens immobiliers. Que vous soyez un investisseur, un promoteur ou un propriétaire, nos services de consultance sont conçus pour répondre à vos besoins spécifiques et vous guider dans la prise de décisions éclairées.
          </p>

          <h3>Nos Domaines d'Intervention</h3>
          <div className="service-list">
            <div className="service-item-consultations">
              <FontAwesomeIcon icon={faChartLine} className="service-icon-consultations" />
              <div>
                <h4>Conseil en Investissement</h4>
                <p>Nous analysons les opportunités d'investissement et vous conseillons sur les meilleures stratégies pour maximiser vos rendements.</p>
              </div>
            </div>
            <div className="service-item-consultations">
              <FontAwesomeIcon icon={faLightbulb} className="service-icon-consultations" />
              <div>
                <h4>Stratégie de Développement</h4>
                <p>Nous vous aidons à élaborer des plans de développement immobilier qui répondent aux besoins du marché et aux objectifs financiers.</p>
              </div>
            </div>
            <div className="service-item-consultations">
              <FontAwesomeIcon icon={faCog} className="service-icon-consultations" />
              <div>
                <h4>Optimisation des Opérations</h4>
                <p>Nous identifions les améliorations possibles dans la gestion de vos biens pour optimiser les performances et réduire les coûts.</p>
              </div>
            </div>
            <div className="service-item-consultations">
              <FontAwesomeIcon icon={faHandsHelping} className="service-icon-consultations" />
              <div>
                <h4>Accompagnement Personnalisé</h4>
                <p>Nos experts sont à vos côtés pour vous conseiller à chaque étape de votre projet immobilier.</p>
              </div>
            </div>
          </div>

          <h3>Nos Zones d'Intervention</h3>
          <p>
            Nos services de consultance couvrent les régions de Dakar et de Thiès, ainsi que d'autres localités au Sénégal. Nous offrons des solutions sur mesure pour répondre aux particularités du marché immobilier local.
          </p>
        </div>

        <div className="consultances-images">
          <div className="image-card">
            <img src="/images/contact-support.png" alt="Contactez-nous" />
            <a href="/contact" className="image-link-consultations">Contactez-nous</a>
          </div>
          <div className="image-card">
            <img src="/images/gestionbien.jpg" alt="Gérez votre bien" />
            <a href="/gerance/formulaire" className="image-link-consultations">Confiez-nous votre bien</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Consultances;
