import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Confirmation.css'; // Importer le CSS

function Confirmation() {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const status = query.get('status'); // Récupérer le statut de la vérification (success ou failure)
  const message = query.get('message'); // Récupérer le message de la vérification
  const [verificationStatus, setVerificationStatus] = useState('');

  useEffect(() => {
    if (status) {
      // Définir le statut de vérification à afficher sur la page
      setVerificationStatus(status);
    }
  }, [status]);

  return (
    <div className="confirmation-container">
      <h2>Confirmation de l'inscription</h2>
      <p>{message}</p> {/* Affiche le message de l'URL */}

      {verificationStatus === 'success' && (
        <button onClick={() => navigate('/login')}>Aller à la page de connexion</button>
      )}

      {verificationStatus === 'failure' && (
        <button onClick={() => navigate('/signup')}>Retour à l'inscription</button>
      )}
    </div>
  );
}

export default Confirmation;
