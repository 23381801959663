import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Importer le CSS de la bibliothèque
import './Modifier.css'; // Le CSS pour styliser les cartes
import { Link } from 'react-router-dom';

function Modifier({ locations, page, onPageChange, itemsPerPage = 10 }) {
  
  // Nombre de propriétés par page
  const totalLocations = locations.length; // Nombre total de locations
  const totalPages = Math.ceil(totalLocations / itemsPerPage); // Nombre total de pages
  
  // Calcul des éléments pour la page actuelle
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentLocations = locations.slice(startIndex, endIndex); // Découpage des locations à afficher pour la page courante

  // Render la pagination
  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button 
          key={i} 
          onClick={() => onPageChange(i)} 
          disabled={i === page}
          className={`pagination-number ${i === page ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination-container">
        {/* Bouton pour la première page */}
        <button
          className="pagination-btn"
          onClick={() => onPageChange(1)}
          disabled={page === 1}
        >
          &#171; {/* Guillemet double pointant vers la gauche */}
        </button>

        {/* Bouton pour la page précédente */}
        <button
          className="pagination-btn"
          onClick={() => onPageChange(page - 1)}
          disabled={page === 1}
        >
          &#8249; {/* Flèche simple pointant vers la gauche */}
        </button>

        {/* Numéros de page */}
        {pages}

        {/* Bouton pour la page suivante */}
        <button
          className="pagination-btn"
          onClick={() => onPageChange(page + 1)}
          disabled={page === totalPages}
        >
          &#8250; {/* Flèche simple pointant vers la droite */}
        </button>

        {/* Bouton pour la dernière page */}
        <button
          className="pagination-btn"
          onClick={() => onPageChange(totalPages)}
          disabled={page === totalPages}
        >
          &#187; {/* Guillemet double pointant vers la droite */}
        </button>
      </div>
    );
  };

  return (
    <div className="modifier-container">
      <h1>Modifier une location</h1>
      <div className="locations-container">
        {currentLocations && currentLocations.length > 0 ? (
          currentLocations.map(location => (
            <div className="location-card" key={location._id}>
              <div className="location-image">
                {location.images && location.images.length > 0 ? (
                  <Carousel className="location-carousel" showThumbs={false} infiniteLoop={true}>
                    {location.images.map((image, index) => (
                      <Link to={`/admin/modifier/${location._id}`} key={index}>
                        <div>
                          <img
                            src={image.startsWith('./images/') ? `/images/${image.split('/').pop()}` : image} // Convertir le chemin de l'image
                            alt={`Slide ${index + 1}`}
                          />
                        </div>
                      </Link>
                    ))}
                  </Carousel>
                ) : (
                  <p>Aucune image disponible</p>
                )}
              </div>
              <div className="location-details-l">
                <Link to={`/admin/modifier/${location._id}`} key={location._id}>
                  <h2>{location.title}</h2>
                </Link>
                <p className="location-icon-l">
                  <i className="fas fa-map-marker-alt"></i> {location.localisation}
                </p>
                <p className="location-category-l">{location.category}
                  <span className="location-features">
                    <i className="fas fa-bed"></i> {location.rooms} chambres
                    <i className="fas fa-bath"></i> {location.bathrooms} salles de bains
                    <i className="fas fa-couch"></i> {location.bed} lits
                    <i className="fas fa-chair"></i> {location.lounge} salons
                  </span>
                </p>
                <p className="location-description">{location.description}</p>
                <div className="location-actions-price">
                  <div className="location-actions">
                    <button className="btn-call">Modifier</button>
                    <button className="btn-contact">Supprimer</button>
                  </div>
                  <div className="location-price-container">
                    <p className="location-price">
                      {/* Formatage du prix avec séparateurs de milliers et ajout de l'unité */}
                      {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(location.details.price)} par {location.details.sequence}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Aucune location disponible pour le moment.</p>
        )}
      </div>
      {/* Pagination */}
      {renderPagination()}
    </div>
  );
}

export default Modifier;
