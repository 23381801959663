import React from 'react';
import './Home.css';

//import image1 from 'images/IMG_4140.jpg';
//import image2 from 'images/IMG_4142.jpg';

function Home() {
  return (
    <div className="home-container">
        <div className="banner">
            <div className="banner-content">
                <h1>Réservez vos logements et activités</h1>
                <p>partout au Sénégal</p>
                <button>Réservez vos excursions et packages Hotel</button>
            </div>
        </div>

        {/* Section de recherche */}
        <section className="search-bar">
            <input type="text" placeholder="Ville" />
            <input type="text" placeholder="Nom, quartier" />
            <input type="date" placeholder="Arrivée" />
            <input type="date" placeholder="Départ" />
            <select>
            <option>Chambre(s)</option>
            <option>1 Chambre</option>
            <option>2 Chambres</option>
            <option>3 Chambres</option>
            </select>
            <button>Chercher</button>
        </section>

        {/* Section des meilleurs biens */}
        <section className="best-properties">
            <h2>Nos Meilleurs Biens</h2>
            <p>Réservez l'un de ces endroits uniques pour échapper à l'ordinaire</p>
            <div className="property-cards">
                <div className="property-card">
                    <img src="/images/IMG_4140.jpg" alt="Villa Saly 178" />
                    <h3>Villa Saly 178</h3>
                    <p>Thies</p>
                </div>
                <div className="property-card">
                    <img src="/images/IMG_4140.jpg" alt="Villa Mermoz-Dakar" />
                    <h3>Villa Mermoz-Dakar</h3>
                    <p>Dakar</p>
                </div>
                <div className="property-card">
                    <img src="/images/IMG_4140.jpg" alt="Goodies studio ouest foire" />
                    <h3>Goodies studio ouest foire</h3>
                    <p>Dakar</p>
                </div>
                <div className="property-card">
                    <img src="/images/IMG_4140.jpg" alt="Waterfront Corniche" />
                    <h3>Waterfront Corniche</h3>
                    <p>Dakar</p>
                </div>
            </div>
        </section>

        {/* Section des belles villes */}
        <section className="best-cities">
            <h2>Nos Belles Villes</h2>
            <p>Les villes les plus recherchées le mois dernier</p>
            <div className="city-cards">
                <div className="city-card">
                    <img src="/images/IMG_4140.jpg" alt="Ziguinchor" />
                    <h3>Ziguinchor</h3>
                    <p>La ville de Ziguinchor se situe dans le sud-ouest du Sénégal...</p>
                </div>
                <div className="city-card">
                    <img src="/images/IMG_4140.jpg" alt="Fatick" />
                    <h3>Fatick</h3>
                    <p>Le Sine Saloum est une région naturelle qui se trouve au Nord...</p>
                </div>
                <div className="city-card">
                    <img src="/images/IMG_4140.jpg" alt="Dakar" />
                    <h3>Dakar</h3>
                    <p>Dakar, capitale du Sénégal depuis le 4 avril 1960...</p>
                </div>
            </div>
        </section>

        {/* Section des propriétés les plus récentes */}
        <section className="recent-properties">
            <h2>Propriétés les plus récentes</h2>
            <p>Dernières propriétés publiées de nos hôtes</p>
            <div className="recent-property-cards">
                <img src="/images/IMG_4142.jpg" alt="Property 1" />
                <img src="/images/IMG_4142.jpg" alt="Property 2" />
                <img src="/images/IMG_4142.jpg" alt="Property 3" />
                <img src="/images/IMG_4142.jpg" alt="Property 4" />
            </div>
        </section>
    </div>
  );
}

export default Home;
