import React from 'react';
import './Vente.css';

function Vente() {
  return (
    <div className="service-page">
      <div className="vente-header">
        <h1>Service de Vente Immobilière</h1>
        <p>Accompagnez vos projets de vente avec des experts dédiés et efficaces.</p>
      </div>
      
      <div className="vente-content">
        <h2>Pourquoi choisir Mentor Business Services pour la vente de votre bien ?</h2>
        <p>
          Vendre un bien immobilier peut être un processus complexe et exigeant. Chez Mentor Business Services, nous mettons à votre disposition notre expertise et notre expérience pour vous accompagner à chaque étape de la vente. Notre objectif est de maximiser la valeur de votre bien tout en assurant une transaction fluide et sécurisée.
        </p>

        <h3>Nos Services de Vente</h3>
        <ul>
          <li><strong>Évaluation Précise :</strong> Nos experts réalisent une évaluation détaillée de votre bien en tenant compte du marché local, des caractéristiques spécifiques de votre propriété et des tendances actuelles pour déterminer le meilleur prix de vente.</li>
          <li><strong>Marketing et Promotion :</strong> Nous élaborons des stratégies de marketing personnalisées pour augmenter la visibilité de votre bien, incluant des annonces en ligne, des visites virtuelles, et des campagnes publicitaires ciblées.</li>
          <li><strong>Gestion des Négociations :</strong> Nous gérons les négociations avec les acheteurs potentiels pour obtenir les meilleures conditions de vente possibles, tout en vous tenant informé à chaque étape.</li>
          <li><strong>Assistance Juridique :</strong> Notre équipe assure la gestion des aspects juridiques de la transaction, garantissant que tous les documents sont en ordre et conformes aux réglementations en vigueur.</li>
          <li><strong>Finalisation de la Transaction :</strong> Nous coordonnons toutes les démarches nécessaires à la conclusion de la vente, y compris la signature des contrats et le transfert de propriété.</li>
        </ul>

        <h3>Pourquoi Nous Faire Confiance ?</h3>
        <p>
          Mentor Business Services se distingue par son engagement à fournir un service client exceptionnel. Nous comprenons que chaque vente est unique et nécessite une approche personnalisée. Notre équipe dévouée travaille en étroite collaboration avec vous pour répondre à vos besoins spécifiques et garantir que votre expérience de vente soit réussie et sans stress.
        </p>

        <h3>Nos Zones d'Intervention</h3>
        <p>
          Nous opérons principalement à Dakar et Thiès, mais nos services de vente immobilière sont disponibles dans tout le Sénégal. Notre connaissance approfondie du marché local nous permet de vous offrir des conseils stratégiques adaptés à chaque région, assurant ainsi le succès de vos transactions immobilières.
        </p>
      </div>
    </div>
  );
}

export default Vente;
