import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import axios from 'axios';

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://booking-backend-3897450fe5a2.herokuapp.com/api/users/login', { email, password });
      const { token } = response.data;
      //alert(response.data.message);
      // Stocker le token dans localStorage
      localStorage.setItem('authToken', token);
      // Appeler onLogin avec le rôle de l'utilisateur
      onLogin(token);
      navigate('/');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Email ou mot de passe incorrect');
    }
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <h2>S'IDENTIFIER</h2>
        <p>S'identifier avec votre compte de média social</p>
        <div className="social-login">
          <button className="social-btn"><i className="fab fa-facebook-f"></i></button>
          <button className="social-btn"><i className="fab fa-google"></i></button>
        </div>
        <div className="divider">OU</div>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="Votre email" 
              required 
            />
          </div>
          <div className="form-group">
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Votre mot de passe" 
              required 
            />
            <span className="show-password"><i className="far fa-eye"></i></span>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="forgot-password">
            <Link to="/forgot-password">Mot de passe oublié ?</Link>
          </div>
          <button type="submit" className="btn-login">S'identifier</button>
        </form>
      </div>

      <div className="login-right">
        <h2>Salut!</h2>
        <p>Entrez vos informations personnelles et commencez le voyage avec nous</p>
        <Link to="/signup" className="btn-signup-login">Inscription</Link>
      </div>
    </div>
  );
}

export default Login;
