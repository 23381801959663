import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPhone, faUserCircle, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

// Importation des pages
import Presentation from './pages/Presentation';
import Locations from './pages/Locations';
import DetailPage from './pages/DetailPage';
import Ventes from './pages/Ventes';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Login from './pages/Connexion/Login';
import Signup from './pages/Connexion/Signup';
import Confirmation from './pages/Connexion/Confirmation';
import EmailSent from './pages/Connexion/EmailSent';
import Profile from './pages/Connexion/Profile';

// Importation des sous-pages de locations
import Appartements from './pages/Appartements';
import Villas from './pages/Villas';
import Terrains from './pages/Terrains';
import Saisonniere from './pages/Saisonniere';
import Chambres from './pages/Chambres';

// Importation des pages services
import Gerance from './pages/Services/Gerance';
import Location from './pages/Services/Location';
import Vente from './pages/Services/Vente';
import EtudesExpertises from './pages/Services/EtudesExpertises';
import Consultances from './pages/Services/Consultances';
import BTP from './pages/Services/BTP';

// Importation des pages administrateur
import Ajouter from './pages/Admin/Ajouter';
import Modifier from './pages/Admin/Modifier';
import Supprimer from './pages/Admin/Supprimer';
import ModifierPage from './pages/Admin/ModifierPage';
import SupprimerPage from './pages/Admin/SupprimerPage';

// Importation du Footer
import Footer from './components/Footer';

// Créer une instance Axios avec l'URL de base
const api = axios.create({
  //baseURL: 'http://localhost:5000/api/properties', // Définir une fois l'URL de base
  baseURL: 'https://booking-backend-3897450fe5a2.herokuapp.com/api/properties',
});

function App() {
  const [locations, setLocations] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Nouvel état pour la connexion
  const [showProfileMenu, setShowProfileMenu] = useState(false); // Pour afficher le menu du profil
  const [userRole, setUserRole] = useState(''); // Pour stocker le rôle de l'utilisateur
  const [userEmail, setUserEmail] = useState('');

  // Ajout pour la pagination et les filtres
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // Définir une limite par page
  const [totalPages, setTotalPages] = useState(1);
  const [category, setCategory] = useState('');
  const [localisation, setLocalisation] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [sort, setSort] = useState('');
  const [search, setSearch] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [maxPriceQuery, setMaxPriceQuery] = useState('');

  const [menuActive, setMenuActive] = useState(false); // État pour contrôler le menu

  // Fonction pour gérer l'affichage/masquage du menu
  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  // Fonction pour fermer le menu lorsqu'une option est cliquée
  const closeMenu = () => {
    setMenuActive(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.profile-dropdown') && !event.target.closest('.profile-icon')) {
        setShowProfileMenu(false);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);  

  useEffect(() => {
    // Sélectionner l'icône du menu et le menu lui-même une fois que le composant est monté
    const menuIcon = document.querySelector('.menu-icon');
    const navbarMenu = document.querySelector('.navbar-menu');
  
    // Ajouter un événement pour afficher/masquer le menu au clic, si les éléments existent
    if (menuIcon && navbarMenu) {
      const toggleMenu = () => {
        navbarMenu.classList.toggle('active');
      };
      menuIcon.addEventListener('click', toggleMenu);
  
      // Nettoyage lors du démontage du composant
      return () => {
        menuIcon.removeEventListener('click', toggleMenu);
      };
    }
  }, []); // Le tableau vide [] garantit que l'effet est exécuté une seule fois après le montage  

  // Fonction pour changer de prix maximum (ne met pas à jour directement maxPriceQuery)
  const handleMaxPriceChange = (newMaxPrice) => {
    setMaxPrice(newMaxPrice); // Mettre à jour maxPrice sans déclencher de recherche
  };

  // Fonction pour changer le tri
  const handleSortChange = (newSort) => {
    setSort(newSort);
    setPage(1); // Reset à la première page lors du changement de tri
  };

  // Fonction pour gérer les changements de l'input de recherche
  const handleSearchChange = (newSearch) => {
    setSearch(newSearch); // Met à jour l'état de recherche
  };

  // Fonction pour déclencher la recherche
  const handleSearchClick = () => {
    setSearchQuery(search); // Mettre à jour searchQuery pour déclencher la recherche
    setMaxPriceQuery(maxPrice); // Mettre à jour maxPriceQuery pour déclencher la recherche
    setPage(1); // Reset à la première page lors du changement de recherche
  };

  // Vérifier le token au chargement de l'application
  useEffect(() => {
    // Fonction pour récupérer les propriétés avec pagination et filtres
    const fetchLocations = async () => {
      try {
          const response = await api.get('/', {
              params: {
                  page,
                  limit,
                  category,
                  localisation,
                  sort,
                  search: searchQuery,
              },
          });

          let filteredProperties = response.data.properties;

          // Appliquer les filtres de prix côté front-end
          if (minPrice) {
            filteredProperties = filteredProperties.filter(property => property.details.price >= parseFloat(minPrice));
          }
          if (maxPriceQuery) {
            filteredProperties = filteredProperties.filter(property => property.details.price <= parseFloat(maxPriceQuery));
          }

          // Mettre à jour les propriétés et la pagination
          //setLocations(filteredProperties.slice((page - 1) * limit, page * limit));
          //setTotalPages(Math.ceil(filteredProperties.length / limit));
          setLocations(filteredProperties); // Retirez .slice() ici
          setTotalPages(Math.ceil(response.data.total / limit));
      } catch (error) {
          console.error('Erreur lors de la récupération des propriétés:', error);
      }
    };

    // Vérifier si l'utilisateur est déjà connecté
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setIsLoggedIn(true);
        setUserRole(decoded.role);
        setUserEmail(decoded.email);
      } catch (error) {
        console.error('Token invalide:', error);
      }
    }

    // Appeler fetchLocations pour obtenir les données initiales
    fetchLocations(); 

  }, [page, limit, category, localisation, minPrice, maxPriceQuery, sort, searchQuery]); // Re-exécuter useEffect lorsque page, category, localisation changent

  // Fonction pour changer de page
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Fonction pour changer de catégorie
  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
    setPage(1); // Reset à la première page lors du changement de filtre
  };

  // Fonction pour changer de localisation
  const handleLocalisationChange = (newLocalisation) => {
    setLocalisation(newLocalisation);
    setPage(1); // Reset à la première page lors du changement de filtre
  };

  // Fonction pour changer de prix minimum
  const handleMinPriceChange = (newMinPrice) => {
    setMinPrice(newMinPrice);
    setPage(1); // Reset à la première page lors du changement de filtre
  };

  // Fonction pour changer de prix maximum
  // Fonction de connexion (à appeler après une connexion réussie)
  const handleLogin = (token) => {
    try {
      const decoded = jwtDecode(token);
      setIsLoggedIn(true);
      setUserRole(decoded.role);
      setUserEmail(decoded.email);
      localStorage.setItem('authToken', token);
    } catch (error) {
      console.error('Erreur lors de la connexion:', error);
    }
  };

  // Fonction de déconnexion
  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserRole('');
    setUserEmail('');
    setShowProfileMenu(false); // Fermer le menu de profil
    localStorage.removeItem('authToken');
    // Ajoutez ici une logique pour gérer la déconnexion (par exemple, supprimer le token de session)
    // Rediriger l'utilisateur vers la page d'accueil
    window.location.href = '/';
  };

  // Gestion de l'affichage du menu de profil
  /*const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };*/
  const toggleProfileMenu = () => {
    setShowProfileMenu((prev) => !prev);
  };
  
  // Fonction pour fermer le menu profil
  const closeProfileMenu = () => {
    setShowProfileMenu(false);
  };  
  
  // Fonction pour ajouter une nouvelle location
  const handleAddLocation = (newLocation) => {
    setLocations([...locations, newLocation]);
  };

  // Fonction pour mettre à jour une location
  const handleUpdateLocation = async (updatedLocation) => {
    try {
      // Faire un appel API PUT pour mettre à jour la propriété dans la base de données
      //const response = await axios.put(`http://localhost:5000/api/properties/${updatedLocation._id}`, updatedLocation);
      const response = await api.put(`/${updatedLocation._id}`, updatedLocation);
      
      // Mettre à jour l'état local si la modification a été réussie
      setLocations((prevLocations) =>
        prevLocations.map((location) =>
          location._id === updatedLocation._id ? response.data : location
        )
      );
      console.log('Propriété mise à jour avec succès:', response.data);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la propriété:', error);
      alert('Erreur lors de la mise à jour de la propriété.');
    }
  };  
  
  // Fonction pour supprimer une location dans la BDD et l'état local
  const handleDeleteLocation = async (_id) => {
    try {
      //await axios.delete(`http://localhost:5000/api/properties/${_id}`);
      await api.delete(`/${_id}`);
      setLocations((prevLocations) =>
        prevLocations.filter((location) => location._id !== _id)
      );
    } catch (error) {
      console.error('Erreur lors de la suppression de la propriété:', error);
    }
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          {/* Barre de menu */}
          <nav className="navbar">
            <div className="navbar-container">
              <div className="menu-icon" onClick={toggleMenu}>
                {menuActive ? '✕' : '☰'}
              </div>
              <div className="navbar-home">
                <Link to="/">
                  <FontAwesomeIcon icon={faHome} className="home-icon" />
                </Link>
              </div>
              <ul className={`navbar-menu ${menuActive ? 'active' : ''}`}>
                <li><Link to="/presentation" onClick={closeMenu}>Présentation</Link></li>

                <li className="dropdown">
                  <Link to="/locations">Locations</Link>
                  <ul className="dropdown-content">
                    <li><Link to="/locations-appartements" onClick={closeMenu}>Appartements</Link></li>
                    <li><Link to="/locations-villas" onClick={closeMenu}>Villas/Bureaux</Link></li>
                    <li><Link to="/locations-saisonnieres" onClick={closeMenu}>Saisonnières</Link></li>
                    <li><Link to="/locations-chambres-studios" onClick={closeMenu}>Chambres/Studios</Link></li>
                  </ul>
                </li>

                <li className="dropdown">
                  <Link to="/ventes">Ventes</Link>
                  <ul className="dropdown-content">
                    <li><Link to="/ventes/terrains" onClick={closeMenu}>Terrains</Link></li>
                    <li><Link to="/ventes/appartements" onClick={closeMenu}>Appartement</Link></li>
                    <li><Link to="/ventes/villas" onClick={closeMenu}>Maisons</Link></li>
                    <li><Link to="/ventes/terrains" onClick={closeMenu}>Champs</Link></li>
                  </ul>
                </li>

                {/* Nouveau dropdown pour Services */}
                <li className="dropdown">
                  <Link to="#">Services</Link>
                  <ul className="dropdown-content">
                    <li><Link to="/services/gerance" onClick={closeMenu}>Gérance</Link></li>
                    <li><Link to="/services/etudes-expertises" onClick={closeMenu}>Études et Expertises</Link></li>
                    <li><Link to="/services/consultances" onClick={closeMenu}>Consultances</Link></li>
                    <li><Link to="/services/btp" onClick={closeMenu}>BTP</Link></li>
                  </ul>
                </li>

                <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>

                {/* Afficher l'onglet Administrateur seulement si l'utilisateur est un admin */}
                {userRole === 'admin' && (
                  <li className="dropdown">
                    <Link to="#">Administrateur</Link>
                    <ul className="dropdown-content">
                      <li><Link to="/admin/ajouter" onClick={closeMenu}>Ajouter</Link></li>
                      <li><Link to="/admin/modifier" onClick={closeMenu}>Modifier</Link></li>
                      <li><Link to="/admin/supprimer" onClick={closeMenu}>Supprimer</Link></li>
                    </ul>
                  </li>
                )}
              </ul>

              {/* Si l'utilisateur est connecté, affiche l'icône de profil avec le menu */}
              {isLoggedIn ? (
                <li className="profile-menu">
                  <div className="profile-icon" onClick={toggleProfileMenu}>
                    <FontAwesomeIcon icon={faUserCircle} className="user-icon" />
                    <FontAwesomeIcon icon={faCaretDown} className="caret-icon" />
                  </div>
                  {showProfileMenu && (
                    <div className="profile-dropdown">
                      <Link to="/profile" onClick={closeProfileMenu}>Infos du profil</Link>
                      <button to="/" onClick={handleLogout}>Déconnexion</button>
                    </div>
                  )}
                </li>
              ) : (
                <li className="profile-menu-login"><Link to="/login" onClick={closeMenu}>Connexion</Link></li>
              )}
              <div className="navbar-contact">
                <Link to="tel:+221338245500">
                    <FontAwesomeIcon icon={faPhone} className="phone-icon" />
                </Link>
                <a href="tel:+221338245500">+221 33 100 00 00</a>
              </div>
  
            </div>
          </nav>
        </header>

        {/* Contenu des pages */}
        <main>
          <Routes>
            <Route path="/presentation" element={<Presentation />} />
            <Route path="/locations" element={
              <Locations 
                locations={locations} 
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                onCategoryChange={handleCategoryChange}
                onLocalisationChange={handleLocalisationChange}
                onMinPriceChange={handleMinPriceChange}
                onMaxPriceChange={handleMaxPriceChange}
                onSortChange={handleSortChange}
                onSearchClick={handleSearchClick} // Assurez-vous de passer handleSearchClick au composant
                onSearchChange={handleSearchChange}
                minPrice={minPrice}
                maxPrice={maxPrice}
                category={category}
                localisation={localisation}
              />
            } />
            <Route path="/detail/:id" element={<DetailPage locations={locations} />} />
            <Route path="/ventes" element={<Ventes />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/email-sent" element={<EmailSent />} />
            <Route path="/profile" element={<Profile userEmail={userEmail} />} />
            <Route path="/" element={<Home />} />

            {/* Routes des sous-pages de locations */}
            <Route path="/locations-appartements" element={
              <Appartements 
                locations={locations} 
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                onCategoryChange={handleCategoryChange}
                onLocalisationChange={handleLocalisationChange}
                onMinPriceChange={handleMinPriceChange}
                onMaxPriceChange={handleMaxPriceChange}
                onSortChange={handleSortChange}
                onSearchClick={handleSearchClick} // Assurez-vous de passer handleSearchClick au composant
                onSearchChange={handleSearchChange}
                minPrice={minPrice}
                maxPrice={maxPrice}
                category={category}
                localisation={localisation}
              />
            } />
            <Route path="/locations-villas" element={
              <Villas 
                locations={locations} 
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                onCategoryChange={handleCategoryChange}
                onLocalisationChange={handleLocalisationChange}
                onMinPriceChange={handleMinPriceChange}
                onMaxPriceChange={handleMaxPriceChange}
                onSortChange={handleSortChange}
                onSearchClick={handleSearchClick} // Assurez-vous de passer handleSearchClick au composant
                onSearchChange={handleSearchChange}
                minPrice={minPrice}
                maxPrice={maxPrice}
                category={category}
                localisation={localisation}
              />
            } />
            <Route path="/locations-saisonnieres" element={
              <Saisonniere 
                locations={locations} 
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                onCategoryChange={handleCategoryChange}
                onLocalisationChange={handleLocalisationChange}
                onMinPriceChange={handleMinPriceChange}
                onMaxPriceChange={handleMaxPriceChange}
                onSortChange={handleSortChange}
                onSearchClick={handleSearchClick} // Assurez-vous de passer handleSearchClick au composant
                onSearchChange={handleSearchChange}
                minPrice={minPrice}
                maxPrice={maxPrice}
                category={category}
                localisation={localisation}
              />
            } />

            <Route path="/locations-chambres-studios" element={
              <Chambres
                locations={locations} 
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                onCategoryChange={handleCategoryChange}
                onLocalisationChange={handleLocalisationChange}
                onMinPriceChange={handleMinPriceChange}
                onMaxPriceChange={handleMaxPriceChange}
                onSortChange={handleSortChange}
                onSearchClick={handleSearchClick} // Assurez-vous de passer handleSearchClick au composant
                onSearchChange={handleSearchChange}
                minPrice={minPrice}
                maxPrice={maxPrice}
                category={category}
                localisation={localisation}
              />
            } />

            {/* Routes des sous-pages de ventes */}
            <Route path="/ventes/appartements" element={<Appartements />} />
            <Route path="/ventes/villas" element={<Villas />} />
            <Route path="/ventes/terrains" element={<Terrains />} />

            {/* Routes pour les services */}
            <Route path="/services/gerance" element={<Gerance />} />
            <Route path="/services/location" element={<Location />} />
            <Route path="/services/vente" element={<Vente />} />
            <Route path="/services/etudes-expertises" element={<EtudesExpertises />} />
            <Route path="/services/consultances" element={<Consultances />} />
            <Route path="/services/btp" element={<BTP />} />

            {/* Routes des pages administrateur */}
            <Route path="/admin/ajouter" element={<Ajouter onAddLocation={handleAddLocation} />} />
            <Route path="/admin/modifier" element={
              <Modifier 
                locations={locations} 
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            } />
            <Route path="/admin/modifier/:id" element={<ModifierPage locations={locations} onUpdateLocation={handleUpdateLocation} />} />
            <Route path="/admin/supprimer" element={
              <Supprimer 
                locations={locations} 
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            } />
            <Route path="/admin/supprimer/:id" element={<SupprimerPage locations={locations} onDeleteLocation={handleDeleteLocation} />} />
          </Routes>
        </main>
        {/* Ajout du footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;